<template>
  <div class="rolemanage">
    <div class="rolename">
      <span class="roletitle">角色名称</span>
      <div class="rolealone">
        <span class="alonename">开发</span>
        <span class="alonename">运营</span>
        <span class="alonename">产品</span>
        <span class="alonename">销售</span>
        <span class="alonename">客服</span>
        <span class="alonename">财务</span>
      </div>
      <div class="rolebutton">
        <el-button class="rolebut" @click="dialogVisible=true">添加</el-button>
        <el-button class="rolebut">删除</el-button>
      </div>
    </div>
    <div class="selectpermissions">
      <span class="selecttitle">选择权限</span>
      <div class="selectcollapse">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item name="1">
            <template slot="title">
              <el-checkbox class="checkboxs">系统查询</el-checkbox>
            </template>
            <div class="all">
              <div class="checkbox2">
                <div class="titlecheckbox">
                  <el-checkbox class="checkboxs">中心看板</el-checkbox>
                </div>
                <div>
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox label="功能权限"></el-checkbox>
                    <el-checkbox label="功能权限2"></el-checkbox>
                    <el-checkbox label="功能权限3"></el-checkbox>
                    <el-checkbox label="功能权限4"></el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
              <div class="checkbox2">
                <div class="titlecheckbox">
                  <el-checkbox class="checkboxs">用户信息</el-checkbox>
                </div>
                <div>
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox label="功能权限q"></el-checkbox>
                    <el-checkbox label="功能权限2q"></el-checkbox>
                    <el-checkbox label="功能权限w"></el-checkbox>
                    <el-checkbox label="功能权限e"></el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
              <div class="checkbox2">
                <div class="titlecheckbox">
                  <el-checkbox class="checkboxs">客服记录</el-checkbox>
                </div>
                <div>
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox label="功能权限r"></el-checkbox>
                    <el-checkbox label="功能权限t"></el-checkbox>
                    <el-checkbox label="功能权限y"></el-checkbox>
                    <el-checkbox label="功能权限u"></el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
              <div class="checkbox2">
                <div class="titlecheckbox">
                  <el-checkbox class="checkboxs">生活服务统计</el-checkbox>
                </div>
                <div>
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox label="功能权限i"></el-checkbox>
                    <el-checkbox label="功能权限o"></el-checkbox>
                    <el-checkbox label="功能权限p"></el-checkbox>
                    <el-checkbox label="功能权限a"></el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
              <div class="checkbox2">
                <div class="titlecheckbox">
                  <el-checkbox class="checkboxs">反馈建议统计</el-checkbox>
                </div>
                <div>
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox label="功能权限s"></el-checkbox>
                    <el-checkbox label="功能权限d"></el-checkbox>
                    <el-checkbox label="功能权限f"></el-checkbox>
                    <el-checkbox label="功能权限g"></el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item>
            <template slot="title">
              <el-checkbox class="checkboxs">内容管理</el-checkbox>
            </template>
            <div class="all">
              <div class="checkbox2">
                <div class="titlecheckbox">
                  <el-checkbox class="checkboxs">通用知识管理库</el-checkbox>
                </div>
                <div>
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox label="功能权限h"></el-checkbox>
                    <el-checkbox label="功能权限j"></el-checkbox>
                    <el-checkbox label="功能权限k"></el-checkbox>
                    <el-checkbox label="功能权限l"></el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
              <div class="checkbox2">
                <div class="titlecheckbox">
                  <el-checkbox class="checkboxs">专用知识库管理</el-checkbox>
                </div>
                <div>
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox label="功能权限z"></el-checkbox>
                    <el-checkbox label="功能权限x"></el-checkbox>
                    <el-checkbox label="功能权限c"></el-checkbox>
                    <el-checkbox label="功能权限v"></el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
              <div class="checkbox2">
                <div class="titlecheckbox">
                  <el-checkbox class="checkboxs">有声节目维护</el-checkbox>
                </div>
                <div>
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox label="功能权限b"></el-checkbox>
                    <el-checkbox label="功能权限n"></el-checkbox>
                    <el-checkbox label="功能权限m"></el-checkbox>
                    <el-checkbox label="功能权限,"></el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
              <div class="checkbox2">
                <div class="titlecheckbox">
                  <el-checkbox class="checkboxs">电台节目维护</el-checkbox>
                </div>
                <div>
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox label="功权限"></el-checkbox>
                    <el-checkbox label="功能权"></el-checkbox>
                    <el-checkbox label="能权限3"></el-checkbox>
                    <el-checkbox label="功能权4"></el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
              <div class="checkbox2">
                <div class="titlecheckbox">
                  <el-checkbox class="checkboxs">实时数据推送</el-checkbox>
                </div>
                <div>
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox label="功权能限"></el-checkbox>
                    <el-checkbox label="能共权限2"></el-checkbox>
                    <el-checkbox label="功能线限3"></el-checkbox>
                    <el-checkbox label="功能权权"></el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item>
            <template slot="title">
              <el-checkbox class="checkboxs">呼叫中心</el-checkbox>
            </template>
            <div class="all">
              <div class="checkbox2">
                <div class="titlecheckbox">
                  <el-checkbox class="checkboxs">呼叫中心</el-checkbox>
                </div>
                <div>
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox label="功能权限公"></el-checkbox>
                    <el-checkbox label="功能权限能"></el-checkbox>
                    <el-checkbox label="功能权限权"></el-checkbox>
                    <el-checkbox label="功能能权"></el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item>
            <template slot="title">
              <el-checkbox class="checkboxs">系统维护</el-checkbox>
            </template>
            <div class="all">
              <div class="checkbox2">
                <div class="titlecheckbox">
                  <el-checkbox class="checkboxs">用户管理</el-checkbox>
                </div>
                <div>
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox label="1功能权限"></el-checkbox>
                    <el-checkbox label="2功能权限"></el-checkbox>
                    <el-checkbox label="3功能权限"></el-checkbox>
                    <el-checkbox label="4功能权限"></el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
              <div class="checkbox2">
                <div class="titlecheckbox">
                  <el-checkbox class="checkboxs">子中心管理</el-checkbox>
                </div>
                <div>
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox label="功限能权限"></el-checkbox>
                    <el-checkbox label="能功能权限"></el-checkbox>
                    <el-checkbox label="权功能权限"></el-checkbox>
                    <el-checkbox label="限功能权限"></el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
              <div class="checkbox2">
                <div class="titlecheckbox">
                  <el-checkbox class="checkboxs">参数配置</el-checkbox>
                </div>
                <div>
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox label="功能权工限"></el-checkbox>
                    <el-checkbox label="功公能权限"></el-checkbox>
                    <el-checkbox label="功能权共限"></el-checkbox>
                    <el-checkbox label="功能2权限"></el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
              <div class="checkbox2">
                <div class="titlecheckbox">
                  <el-checkbox class="checkboxs">终端管理</el-checkbox>
                </div>
                <div>
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox label="功2能权限"></el-checkbox>
                    <el-checkbox label="功能3权限"></el-checkbox>
                    <el-checkbox label="功4能权限"></el-checkbox>
                    <el-checkbox label="功能6权限"></el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
              <div class="checkbox2">
                <div class="titlecheckbox">
                  <el-checkbox class="checkboxs">版本管理</el-checkbox>
                </div>
                <div>
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox label="功能1权限"></el-checkbox>
                    <el-checkbox label="功2能权限"></el-checkbox>
                    <el-checkbox label="功能7权限"></el-checkbox>
                    <el-checkbox label="功能权限8"></el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
              <div class="checkbox2">
                <div class="titlecheckbox">
                  <el-checkbox class="checkboxs">备份管理</el-checkbox>
                </div>
                <div>
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox label="功能权限7"></el-checkbox>
                    <el-checkbox label="功能权限8"></el-checkbox>
                    <el-checkbox label="功能权限9"></el-checkbox>
                    <el-checkbox label="功能权限0"></el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
              <div class="checkbox2">
                <div class="titlecheckbox">
                  <el-checkbox class="checkboxs">系统日志</el-checkbox>
                </div>
                <div>
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox label="权限功能权限"></el-checkbox>
                    <el-checkbox label="9功能权限"></el-checkbox>
                    <el-checkbox label="i功能权限"></el-checkbox>
                    <el-checkbox label="q功能权限"></el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <el-button class="rolebut">保存</el-button>
    </div>

    <!-- 弹框 -->
    <el-dialog :modal="false" :visible.sync="dialogVisible" width="30%" style="margin-top: 20vh;">
      <rolename></rolename>
    </el-dialog>
  </div>

  <!-- 
  

  -->
</template>

<script>
import rolename from "../system_maintain/rolename";
export default {
  data() {
    return {
      activeName: "1",
      // 弹框
      dialogVisible: false,
      checkList: ["功能权限", "功能权限2", "功能权限3", "功能权限4"]
    };
  },
  components: {
    rolename
  }
};
</script>

<style lang="less">
.rolemanage {
  height: 750px;
  display: flex;
  justify-content: space-between;
  .rolename {
    width: 18%;
    height: 700px;
    text-align: center;
    margin-right: 20px;
    .roletitle {
      display: block;
      font-size: 24px;
      color: #92aca7;
      margin-top: 49px;
      font-weight: bolder;
      margin-bottom: 25px;
    }
    .rolealone {
      height: 400px;
      overflow: auto;
      .alonename {
        display: block;
        font-size: 20px;
        color: #a8a8a8;
        line-height: 64px;
      }
    }
    // 自定义滚动条
    .rolealone::-webkit-scrollbar-track {
      background-color: #dfdfdf;
      border-radius: 5px;
    }
    .rolealone::-webkit-scrollbar {
      width: 7px;
    }
    .rolebutton {
      .rolebut {
        height: 50px;
        width: 106px;
        background: #d78673;
        border-radius: 25px;
        font-size: 20px;
        color: #ffffff;
        margin-top: 100px;
      }
    }
  }
  .selectpermissions {
    width: 80%;
    height: 700px;
    background: #ffffff;
    margin-right: 30px;
    border-radius: 20px;

    .selecttitle {
      display: block;
      font-size: 22px;
      color: #92aca7;
      margin-top: 49px;
      font-weight: bolder;
      margin-bottom: 25px;
      margin-left: 70px;
    }
    .el-collapse {
      border: none;
    }
    // 自定义滚动条
    .selectcollapse::-webkit-scrollbar-track {
      background-color: #ffffff;
      border-radius: 5px;
    }
    .selectcollapse::-webkit-scrollbar {
      width: 7px;
    }
    .selectcollapse::-webkit-scrollbar-thumb {
      border-radius:4px;
      background: #dfdfdf;
    }
    // 
    .selectcollapse {
      margin-left: 100px;
      margin-right: 28px;
      height: 480px;
      overflow: auto;
      .el-collapse-item__content {
        margin-left: 40px;
        margin-right: 40px;
      }
      //   下边框
      .el-collapse-item__header {
        font-size: 20px;
        color: #a8a8a8;
        font-family: "Bold";
        border-bottom: 1px solid #dfdfdf;
      }
      .el-collapse-item__header.is-active {
        border-bottom-color: #dfdfdf;
      }
      .el-collapse-item__wrap {
        border-bottom: none;
      }
      //   三角
      .el-icon-arrow-right:before {
        content: url("../../assets/images/arrow.png");
      }
      .el-collapse-item__arrow.is-active {
        transform: rotate(180deg);
      }
      .centralkanban {
        font-size: 18px;
        color: #a8a8a8;
      }
      .all {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .checkbox2 {
          display: flex;
          flex-direction: column;
          .titlecheckbox {
            display: flex;
            margin-bottom: 14px;
            margin-top: 14px;
          }
          .secondlevel {
            margin-left: 36px;
            display: flex;
            margin-bottom: 14px;
          }
        }
      }
    }
    // 保存
    .rolebut {
      float: right;
      height: 50px;
      width: 106px;
      background: #d78673;
      border-radius: 25px;
      font-size: 20px;
      color: #ffffff;
      margin-right: 26px;
      margin-top: 20px;
    }
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #a8a8a8;
  }
  .el-checkbox__label {
    font-size: 18px;
    color: #a8a8a8;
  }
  .el-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    color: #a8a8a8;
    margin-left: 38px;
  }
  .checkboxs {
    margin-left: 0px;
    margin-top: 15px;
    .el-checkbox__label {
      font-size: 22px;
    }
  }
  .el-checkbox__inner {
    border: transparent;
    background: url("../../assets/images/rect.png") no-repeat center;
    width: 34px;
    height: 34px;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background: url("../../assets/images/rect-select.png") no-repeat center;
  }
  .el-checkbox__inner::after {
    content: none;
  }
  // 弹框
  .el-dialog {
    border-radius: 18px;
    box-shadow: 0 10px 10px #dcdcdc;
  }
  .el-dialog__header {
    border-radius: 18px 18px 0 0;
  }
  .el-dialog__close {
    color: transparent;
    background-image: url("../../assets/images/close.png");
    background-repeat: no-repeat;
    background-size: 14px;
  }
  .el-dialog__body {
    border-radius: 0 0 18px 18px;
    padding: 10px 0px 0 0px;
    height: 240px;
  }
}
</style>