<template>
  <div class="rolenames">
    <span class="newrole">请输入新的角色名</span>
    <el-input class="inputnewname" v-model="input"></el-input>
    <el-button class="rolebut">确认</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input: ""
    };
  }
};
</script>

<style lang="less">
.rolenames {
  .newrole {
    display: block;
    text-align: center;
    font-size: 22px;
    color: #92aca7;
  }
  .el-input__inner {
      background: #eaeaea;
      border: 2px solid  #cccccc;
      width: 313px;
      margin-left: 130px;
      margin-top: 50px;
      
  }
  .rolebut {
        height: 50px;
        width: 106px;
        background: #d78673;
        border-radius: 25px;
        font-size: 20px;
        color: #ffffff;
        margin-left: 240px;
        margin-top: 40px;
      }
}
</style>