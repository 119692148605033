<template>
  <div
    class="userguanli"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <div class="sousuo">
      <div class="div1">
        <span>用户角色</span>
        <el-select
          class="select"
          v-model="value"
          placeholder="请选择"
          style="width: 60%"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="div1">
        <span>部门</span>
        <el-select class="select" v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="div1">
        <span>关键字</span>
        <el-select
          style="width: 100px"
          class="select"
          v-model="value"
          placeholder="ID"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <input class="inputs" type="text" />
      </div>
      <img class="imgs" src="../../assets/images/query.png" alt />
    </div>
    <div class="tables">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="id" label="编号" width="110"></el-table-column>
        <el-table-column
          prop="center"
          label="用户姓名"
          width="110"
        ></el-table-column>
        <el-table-column prop="zhuwu" label="联系电话"></el-table-column>
        <el-table-column prop="name" label="角色"></el-table-column>
        <el-table-column prop="tel" label="部门"></el-table-column>
        <el-table-column prop="time" label="入职时间"></el-table-column>
        <el-table-column prop="login" label="最近登录"></el-table-column>
        <el-table-column prop="caozuo" label="操作">
          <template slot-scope="scope">
            <img
              class="buts"
              src="../../assets/images/edits.png"
              @click="handleEdit(scope.$index, scope.row)"
              alt
            />
            <img
              class="buts"
              src="../../assets/images/del.png"
              @click="handleEdit1(scope.$index, scope.row)"
              alt
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="totals">
      <!-- 左 -->
      <div class="left">
        <p>
          总用户数
          <span class="spanto">100</span>人
        </p>
      </div>
      <!-- 右 -->
      <div class="total">
        <!-- 分页 -->
        <div class="pages">
          <el-pagination
            :page-size="pagesize"
            :pager-count="pagercount"
            layout="slot, pager"
            :total="100"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
            <img class="lefts" src="../../assets/images/leftpage.png" alt />
            <img class="rights" src="../../assets/images/rightpage.png" alt />
          </el-pagination>
        </div>
        <!-- 按钮 -->
        <div class="buttons">
          <el-button @click="dialogVisible1 = true">添加账号</el-button>
          <el-button @click="dialogVisible = true">角色管理</el-button>
        </div>
      </div>
    </div>

    <!-- 弹框 -->
    <el-dialog :visible.sync="dialogVisible" width="80%">
      <rolemanage></rolemanage>
    </el-dialog>

    <div class="accountadd">
      <el-dialog :visible.sync="dialogVisible1" width="70%">
        <addaccount></addaccount>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import rolemanage from "../system_maintain/rolemanage";
import addaccount from "../system_maintain/addaccount";
export default {
  data() {
    return {
      clientHeight: document.body.clientHeight,
      // 弹框
      dialogVisible: false,
      dialogVisible1: false,
      pagesize: 8,
      pagercount: 5,
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      //   table
      tableData: [
        {
          id: "201602",
          name: "王小虎",
          center: "518弄",
          zhuwu: "web",
          tel: "1523698758",
          time: "2020-06-25",
          login: "2020-06-14",
        },
        {
          id: "201602",
          name: "王小虎",
          center: "518弄",
          zhuwu: "web",
          tel: "1523698758",
          time: "2020-06-25",
          login: "2020-06-14",
        },
        {
          id: "201602",
          name: "王小虎",
          center: "518弄",
          zhuwu: "web",
          tel: "1523698758",
          time: "2020-06-25",
          login: "2020-06-14",
        },
        {
          id: "201602",
          name: "王小虎",
          center: "518弄",
          zhuwu: "web",
          tel: "1523698758",
          time: "2020-06-25",
          login: "2020-06-14",
        },
        {
          id: "201602",
          name: "王小虎",
          center: "518弄",
          zhuwu: "web",
          tel: "1523698758",
          time: "2020-06-25",
          login: "2020-06-14",
        },
        {
          id: "201602",
          name: "王小虎",
          center: "518弄",
          zhuwu: "web",
          tel: "1523698758",
          time: "2020-06-25",
          login: "2020-06-14",
        },
      ],
    };
  },
  components: {
    rolemanage,
    addaccount,
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    handleEdit(index, row) {
      console.log(index, row);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang="less">
.userguanli {
  // 搜索
  .sousuo {
    // background: red;
    display: flex;
    align-items: center;
    margin-left: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 40px;
    flex-wrap: wrap;
    .div1 {
      margin-right: 20px;
      .inputs {
        width: 231px;
        height: 36px;
        border: 1px solid #e5e5e5;
        margin-left: 5px;
      }
      .inputs:focus {
        outline: 1px solid #e5e5e5; //边框不用border，用outline
      }
      span {
        font-size: 14px;
        color: #909399;
        font-weight: 700;
      }
      .el-input__inner {
        border-radius: 0;
        height: 33px;
        line-height: 33px;
      }
      .el-input__icon {
        height: 100%;
        width: 25px;
        text-align: center;
        transition: all 0.3s;
        line-height: 33px;
      }
      .select {
        margin-left: 20px;
      }
    }
    .imgs {
      width: 49px;
    }
  }
  //   table
  .tables {
    margin-left: 25px;
    margin-right: 40px;
    .tingyong {
      color: rgb(97, 97, 245);
    }
    .buts {
      width: 36px;
      height: 27px;
      margin: 0 10px;
    }
    .el-table {
      // max-height: 500px;
      // overflow: hidden;
      tr {
        th {
          font-size: 14px;
          border: none;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #909399;
        }
        td {
          border: none;
          text-align: center;
          font-size: 14px;
          color: #606266;
        }
      }
      .details {
        height: 30px;
        border-radius: 15px;
      }
    }
    .el-table::before {
      height: 0;
    }
  }
  .totals {
    display: flex;
    justify-content: space-between;
    .left {
      p {
        font-size: 16px;
        display: inline-block;
        margin-right: 80px;
        margin-left: 80px;
        margin-top: 20px;
        color: #606266;
      }
      .spanto {
        color: #92aca7;
        margin-left: 17px;
        margin-right: 12px;
      }
    }
    .total {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 20px;
      margin-right: 100px;
      // 按钮
      .buttons {
        display: flex;
        .el-button {
          width: 130px;
          height: 40px;
          line-height: 40px;
          border-radius: 25px;
          margin-top: 20px;
          background: #d78673;
          color: #ffffff;
          font-size: 16px;
          padding: 0px;
        }
      }
      // page
      .pages {
        display: flex;
        align-items: center;
        .el-pagination {
          padding: 0;
          .el-pager,
          .el-pager li {
            margin-top: 2px;
            color: #afafaf;
            min-width: 50px;
            font-size: 17px;
            font-weight: normal;
          }
          .el-pager li.active {
            color: #d78673;
          }
          .el-pager li:hover {
            color: #d78673;
          }
          img {
            display: inline-block;
          }
          .lefts {
            margin-right: 25px;
          }
          .rights {
            float: right;
            margin-left: 25px;
          }
        }
      }
    }
  }
  // 弹框
  .el-dialog {
    border-radius: 18px;
  }
  .el-dialog__header {
    background: #eaeaea;
    border-radius: 18px 18px 0 0;
  }
  .el-dialog__close {
    color: transparent;
    background-image: url("../../assets/images/close.png");
    background-repeat: no-repeat;
    background-size: 14px;
  }
  .el-dialog__body {
    background: #eaeaea;
    border-radius: 0 0 18px 18px;
    padding: 10px 0px 0 0px;
  }
  .accountadd {
    .el-dialog__header {
      background: #ffffff;
      border-radius: 18px 18px 0 0;
    }
    .el-dialog__body {
      background: #ffffff;
      border-radius: 0 0 18px 18px;
      padding: 10px 0px 0 0px;
      height: 500px;
    }
  }
  /deep/.el-table td,
  .el-table th.is-leaf {
    border-bottom: none !important;
  }
  /deep/.el-table .cell {
    text-align: center !important;
  }
  /deep/.el-table thead tr th.is-leaf {
    border: none;
    border-right: none;
  }
}
</style>