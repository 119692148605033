<template>
  <div class="addaccount">
    <span class="add">添加账号</span>
    <el-form :model="formInline" class="demo-form-inline">
      <div class="leftadd">
        <el-form-item label="编号">
          <el-input v-model="formInline.user" placeholder="898433"></el-input>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input v-model="formInline.user" placeholder="张三"></el-input>
        </el-form-item>
        <el-form-item label="部门">
          <el-select v-model="formInline.region" placeholder="部门">
            <el-option label="上海" value="shanghai"></el-option>
            <el-option label="北京" value="beijing"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="密码">
            <el-input v-model="formInline.user" placeholder="5556"></el-input>
            <el-checkbox v-model="checked">设置为初始密码</el-checkbox>
        </el-form-item>
        <el-form-item label="手机">
          <el-input v-model="formInline.user" placeholder="15269875894"></el-input>
        </el-form-item>
        <el-form-item label="角色">
          <el-select v-model="formInline.region" placeholder="角色">
            <el-option label="开发" value="kaifa"></el-option>
            <el-option label="运营" value="yunying"></el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
    <el-button class="rolebut">确定</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formInline: {
        user: "",
        region: ""
      },
       checked: true
    };
  },
  methods: {
    onSubmit() {
      console.log("submit!");
    }
  }
};
</script>

<style lang="less">
.addaccount {
  .add {
    font-size: 22px;
    color: #92aca7;
    margin-left: 70px;
    margin-top: 20px;
    font-weight: bolder;
  }
  .el-form {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    .el-form-item{
        display: flex;
    }
    .el-form-item__label {
        color: #a8a8a8;
        font-size: 19px;
        font-weight: bolder;
    }
    .leftadd {
        margin-right: 100px;
    }
    .el-input {
      width: 300px;
    }
    .el-checkbox__inner {
         border-radius: 50%;
        height: 34px;
        width: 34px;
        margin-left: 30px;
        margin-right: 15px;
    }
    .el-checkbox__input.is-focus .el-checkbox__inner{
        border-color: #e1e1e1;
    }
   .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
       border-radius: 50%;
       background-color: #ee8572;
        border-color: #ee8572;
        height: 34px;
        width: 34px;
   }
   .el-checkbox__inner::after{
       left: 12px;
       top: 6px;
       width: 6px;
        height: 12px;
        border:3px solid #ffffff;
        border-left: 0;
    border-top: 0;
   }
   .el-checkbox__label {
       color: #a8a8a8;
       font-weight: bolder;
       font-size: 17px;
   }
   .el-checkbox__input.is-checked+.el-checkbox__label{
       color: #a8a8a8;
       font-weight: bolder;
       font-size: 17px;
   }
  }
  .rolebut {
        float: right;
        height: 50px;
        width: 106px;
        background: #d78673;
        border-radius: 25px;
        font-size: 20px;
        color: #ffffff;
        margin-right: 160px;
        margin-top: 100px;
      }
}
</style>